import { Link } from "gatsby"
import React from "react"
import Logo from "../components/svg/Logo"

const Nav = ({ siteTitle, showAbout }) => {
  const buttonHandler = () => {
    showAbout()
  }
  return (
    <nav class="z-100 h-44px shadow2 lg:grid lg:grid-cols-2 fixed px-5 bottom-0 w-full flex flex-row justify-between items-center bg-grey text-base">
      <button
        className={
          "hidden lg:block uppercase focus:outline-none text-left lg:w-16"
        }
        onClick={buttonHandler}
      >
        About
      </button>
      <div className="flex flex-row w-full justify-between">
        <button
          className={"uppercase focus:outline-none text-left lg:hidden lg:w-16"}
          onClick={buttonHandler}
        >
          About
        </button>
        <Link className="uppercase lg:pl-5" to="/overview">
          Overview
        </Link>
        <Link to="/">
          {/* <h1>{siteTitle}</h1> */}
          <Logo />
        </Link>
      </div>
    </nav>
  )
}

export default Nav
