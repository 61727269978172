import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Div100vh, { use100vh } from "react-div-100vh"

import Nav from "./Nav"
import About from "./About"
// import "../styles/main.scss"

const Layout = ({ children, about }) => {
  const [showAbout, setShowAbout] = useState(false)
  const height = use100vh()
  const h95 = height ? height - 44 : "95vh"

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const showAboutFunc = () => {
    showAbout ? setShowAbout(false) : setShowAbout(true)
  }

  return (
    <Div100vh className="no-bar absolute z-0 bg-grey w-screen overflow-hidden text-black">
      <main
        id="main"
        style={{ height: h95, maxHeight: h95 }}
        className="no-bar"
      >
        {children}

        <Nav
          showAbout={showAboutFunc}
          siteTitle={data.site.siteMetadata.title || `Ette`}
        />
        {showAbout ? (
          <About
            about={about}
            opened={showAbout}
            showAboutFunc={showAboutFunc}
          />
        ) : (
          <About about={about} showAboutFunc={showAboutFunc} />
        )}
      </main>
    </Div100vh>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
