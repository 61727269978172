import React from "react"

const Logo = () => {
  return (
    <svg
      width="51"
      height="20"
      viewBox="0 0 51 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.10591 10.5295C3.49287 7.96334 5.09165 6.24236 7.71894 6.24236C10 6.24236 11.5988 8.01425 11.7108 10.5295H3.10591ZM7.81059 3.66599C3.25866 3.66599 0 7.03666 0 11.8024C0 16.5988 3.25866 20 7.81059 20C11.446 20 14.1141 17.78 14.5825 15.0611H11.5682C11.2729 16.3035 9.8778 17.5153 7.81059 17.5153C4.96945 17.5153 3.30957 15.5906 3.10591 12.78H14.7352V11.5988C14.7352 6.47658 11.5682 3.66599 7.81059 3.66599Z"
        fill="#231F20"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="14"
        y="0"
        width="22"
        height="20"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9064 0H35.9145V19.6436H14.9064V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.5051 0H28.4909V4.1446H25.6192H25.2017H21.2098V0H18.1956V4.1446H14.9064V6.80244H18.1956V15.387C18.1039 19.7352 20.4461 19.9185 24.8555 19.501V16.8635C21.332 17.1283 21.22 16.8024 21.22 14.2566V6.80244H25.2119H25.6294H28.5011V15.387C28.4094 19.7352 30.7516 19.9185 35.1609 19.501V16.8635C31.6375 17.1283 31.5255 16.8024 31.5255 14.2566V6.80244H35.9349V4.1446H31.5255V0H31.5051Z"
          fill="#231F20"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.2871 10.5295C39.6741 7.95315 41.2729 6.24236 43.9002 6.24236C46.1812 6.24236 47.78 8.01425 47.892 10.5295H39.2871ZM50.9164 11.5988C50.9164 6.47658 47.7494 3.66599 43.9918 3.66599C39.4399 3.66599 36.1812 7.03666 36.1812 11.8024C36.1812 16.5988 39.4399 20 43.9918 20C47.6272 20 50.2953 17.78 50.7637 15.0611H47.7494C47.4541 16.3035 46.0692 17.5153 43.9918 17.5153C41.1507 17.5153 39.4908 15.5906 39.2871 12.78H50.9164V11.5988Z"
        fill="#231F20"
      />
    </svg>
  )
}

export default Logo
