import React from "react"
import RichText from "./RichText"
import Exit from "../components/svg/Exit"
import { use100vh } from "react-div-100vh"
import Cursor from "../images/EXIT.svg"

const About = ({ opened, about, showAboutFunc }) => {
  const height = use100vh()
  let greyScale = {
    backgroundColor: `rgba(25,25,25,0.6)`,
    cursor: `url(${Cursor}) 25 15, pointer`,
  }

  return (
    <>
      <section
        style={greyScale}
        onClick={showAboutFunc}
        className={
          (opened
            ? "visible transition-all duration-300 opacity-100"
            : "invisible transition-all duration-300 opacity-0") +
          " " +
          "absolute top-0 right-0 flex flex-col z-50 h-screen w-screen transition-all duration-300"
        }
      >
        <div></div>
      </section>
      <section
        style={{ height: "auto", maxHeight: height }}
        className={
          (opened ? "transform translate-y-0" : "transform translate-y-full") +
          " " +
          "absolute bg-grey bottom-0 right-0 flex flex-col z-50 w-screen transition-all duration-300 pb-44px"
        }
      >
        <div className="no-bar about flex flex-col overflow-y-scroll p-5 pb-44px lg:grid lg:grid-cols-2 lg:gap-x-7 lg:p-5 ">
          <button
            className="absolute top-0 right-0 pt-4 pr-4 lg:hidden"
            onClick={showAboutFunc}
          >
            <Exit />
          </button>
          <div className="text-base lg:text-lg">
            <RichText content={about.edges[0].node.aboutDescription.raw} />
          </div>
          <div>
            <div className="text-base flex flex-col uppercase lg:text-base">
              {about.edges[0].node.socialMediaLinks.map((link, i) => {
                return <a href={link.link}>{link.linkTitle}</a>
              })}
            </div>
            <div className="text-base flex flex-row uppercase pt-5 lg:text-base">
              <div className="w-1/3">
                <h2 className="uppercase">Press</h2>
              </div>
              <div className="flex flex-col text-sm normal-case lg:text-base lg:uppercase">
                {about.edges[0].node.pressLinks.map((link, i) => {
                  return <a href={link.link}>{link.linkTitle}</a>
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
