import React from "react"
import { Title, Link, Meta } from "react-head"

const SEO = ({
  title = "Ette",
  meta = "Ette is the design practice of Kate Richard.",
  link,
}) => {
  let url = "https://etteobjects.com"
  return (
    <>
      <Title>{title}</Title>
      <Meta name="description" content={meta} />
      <Link rel="canonical" content={link ? url + link : url} />
    </>
  )
}

export default SEO
